:root {
    --amplify-primary-color: #4caf50;
    --amplify-primary-tint: #4caf50;
    --amplify-primary-shade: #4caf50;
  }
  /* amplify-authenticator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100vh;
  } */

  .quepal-gradient {
    background: #11998e;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #38ef7d, #11998e);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #38ef7d, #11998e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  
  .quepal-gradient-reverse {
    background: #11998e;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #11998e, #38ef7d);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,  #11998e, #38ef7d); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

@media (min-width: 1500px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1440px;
  }
}

body {
  margin-top: 100px;
}

.pills-quepal-gradient .nav-item .nav-link.active {
  background: linear-gradient(to right, #38ef7d, #11998e);
  }